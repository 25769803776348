import React, {useContext , useState, useEffect} from "react";
import { getAvailability, getData } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const providername = 'AvailabilityProvider';
const servicename = 'availability';
const AvailabilityContext = React.createContext();
const AvailabilityUpdateContext = React.createContext();

export function useAvailabilityMatrix(){
    return useContext(AvailabilityContext);
}

export function AvailabilityProvider({children}){
    const [availabilityMatrix, setAvailabilityMatrix] = useState();
    const [date, setDate] = useState(new Date());
    const [duration, setDuration] = useState(14);

    useEffect(()=>{
        getAvailability(date,duration).then((_list)=>{
            LoggerContext.debug(`########### ${providername} list: ${JSON.stringify(_list)}`)
            setAvailabilityMatrix(_list);
        }).catch((error) => {LoggerContext.error("Error " + providername,error)});
    },[date,duration])

    return (
        <AvailabilityContext.Provider value={availabilityMatrix}>
            {children}
        </AvailabilityContext.Provider>
    )
}