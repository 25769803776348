import React, {useContext , useState, useEffect} from "react";
import { getData } from '../feathers'
import {LoggerContext} from "../Utils/logger";

const providername = 'TimeEntryCategoryListProvider';

const TimeEntryCategoryListContext = React.createContext();

export function useTimeEntryCategoryList(){
    return useContext(TimeEntryCategoryListContext);
}

export function TimeEntryCategoryListProvider({children, employee}){
    const [timeEntryCategoryList, setTimeEntryCategoryList] = useState([]);

    useEffect(()=>{
        LoggerContext.debug('########### TimeEntryCategoryListProvider useEffect');
        getData('time-entry-category').then((_list)=>{
            LoggerContext.info(`########### ${providername} list: ${JSON.stringify(_list)}`)
            setTimeEntryCategoryList(_list);
        }).catch((error) => {LoggerContext.error("Error " + providername,error)});
    },[])

    return (
        <TimeEntryCategoryListContext.Provider value={timeEntryCategoryList}>
            {children}
        </TimeEntryCategoryListContext.Provider>
    )
}