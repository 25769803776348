import { useState, useEffect, useRef, useCallback } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { getData, patchData } from '../feathers';
import { SelectButton } from 'primereact/selectbutton';
import './ManageSimpleListPage.scss'
import { useCurrentUser } from '../Persistence/CurrentUserContext';

const emptyValue = { name: '', alias: '', display: '', active:true ,requiredStrength:11,warningLevel:13}

export const ManageSimpleListPage = ({ listname, servicename }) => {
    const [id, setId] = useState();
    const [active, setActive] = useState();
    const [name, setName] = useState();
    const [alias, setAlias] = useState();
    const [display, setDisplay] = useState();
    const [requiredStrength, setRequiredStrength] = useState(11);
    const [warningLevel, setWarningLevel] = useState(13);
    const [selectedValue, setSelectedValue] = useState();
    const [list, setList] = useState([]);
    const op = useRef(null);
    const isMounted = useRef(false);
    const currentUser = useCurrentUser();

    const handleChangeSelectedValue = useCallback((value) => {
        if(!value){
            value = {...emptyValue}
        }
        setSelectedValue(value);
        setId(value.id);
        setName(value.name);
        setAlias(value.alias);
        setDisplay(value.display);
        setActive(value.active);
        setRequiredStrength(value.requiredStrength)
        setWarningLevel(value.warningLevel)
    },[])

    useEffect(() => {
        if (isMounted.current) {
            op.current.hide();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isMounted.current = true;
        getData(servicename).then(_list => setList(_list));
        handleChangeSelectedValue({ ...emptyValue })
    }, [servicename, handleChangeSelectedValue])

    const handleOnClickSaveEntry = async () => {
        const data = { id, name, display, alias , active,requiredStrength,warningLevel,updatedBy:currentUser.id};
        patchData(servicename,data ).then(() =>{
            getData(servicename).then(_list => setList(_list));
        });
        handleChangeSelectedValue({ ...emptyValue });       
        op.current.hide();
    }

    const renderSelectButton = (rowData) => {
        return <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={(e) => { op.current.toggle(e); handleChangeSelectedValue(rowData) }} aria-haspopup aria-controls="users_overlay_panel" ></Button>
    }
    const renderStatus = (rowData) => {
        return <i className={rowData.active?"pi pi-check":"pi pi-times"} style={{'fontSize': '1em'}}></i>
    }

    return (
        <>
            <div className="p-fluid p-p-4" id='manage-simple-list-page' style={{ width: '800px' }}>
                <div className="p-d-flex p-jc-center ">
                    <h3>{listname}</h3>
                </div>
                <div className="p-d-flex p-jc-center" >
                    <Button style={{ width: '200px' }} className='button' onClick={(e) => {handleChangeSelectedValue(emptyValue); op.current.toggle(e);}} label='Neuer Listeneintrag' />
                </div>
                <div className="p-grid p-p-4">
                   <div className="p-col p-p-4">
                        <div className="p-shadow-12">
                            <DataTable scrollable value={list} dataKey="id" className="p-datatable-sm" selection={selectedValue} onSelectionChange={e => handleChangeSelectedValue(e.value)} >
                                <Column body={renderSelectButton} headerStyle={{ width: '100px' }}></Column>
                                <Column body={renderStatus} header="Status" headerStyle={{width: '60px' }}></Column>
                                <Column field="name" header="Name"  ></Column>
                                <Column field="alias" header="Alias" style={{ width: '80px' }} ></Column>
                                <Column field="display" header="Display"  ></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <OverlayPanel dismissable={false} ref={op} showCloseIcon id="users_overlay_panel" style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
                    <div className="p-col-fixed" style={{ width: '400px' }} id='manage-simple-list-page-panel'>
                        <div className="p-col">
                            <div className="p-fluid" style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
                                <div className="p-field ">
                                    <label htmlFor="status" className="p-d-block" >Status</label>
                                    <SelectButton value={active} optionLabel='name' optionValue='value' options={[{name:'aktiv',value:1}, {name:'inaktiv',value:0}]} onChange={(e) => setActive(active===0?1:0)} />
                                </div>
                                <div className="p-field ">
                                    <label htmlFor="name" className="p-d-block" >Name</label>
                                    <InputText autoComplete="nope" value={name} id="newName" aria-describedby="name-help" className={(name === '' ? 'p-invalid' : '') + " p-d-block"} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="p-field ">
                                    <label htmlFor="alias" className="p-d-block" >Alias</label>
                                    <InputText autoComplete="nope" value={alias}  style={{ width: '100px' }} id="newAlias" aria-describedby="alias-help" className={(alias === '' ? 'p-invalid' : '') + " p-d-block"} onChange={(e) => setAlias(e.target.value)} />
                                </div>
                                <div className="p-field ">
                                    <label htmlFor="display" className="p-d-block">Display</label>
                                    <InputText autoComplete="nope" value={display} id="newDisplay" aria-describedby="display-help" className={(display === '' ? 'p-invalid' : '') + " p-d-block"} onChange={(e) => setDisplay(e.target.value)} />
                                </div>
                                {servicename !== 'site'?'':<div>
                                <div className="p-field ">
                                    <label htmlFor="display" className="p-d-block">Mindeststärke</label>
                                    <InputText autoComplete="nope" value={requiredStrength} id="newDisplay" aria-describedby="display-help" className={(display === '' ? 'p-invalid' : '') + " p-d-block"} onChange={(e) => setRequiredStrength(e.target.value)} />
                                </div>
                                <div className="p-field ">
                                    <label htmlFor="display" className="p-d-block">Warnstufe</label>
                                    <InputText autoComplete="nope" value={warningLevel} id="newDisplay" aria-describedby="display-help" className={(display === '' ? 'p-invalid' : '') + " p-d-block"} onChange={(e) => setWarningLevel(e.target.value)} />
                                </div></div>}

                                <div className="p-d-flex">
                                    <Button className='p-button-success button p-m-2' onClick={handleOnClickSaveEntry} disabled={name === '' || alias === '' || display === ''} label='Speichern' />
                                </div>

                            </div>
                        </div>

                    </div>
                </OverlayPanel>
        </>
    )

}