import { useState, useEffect } from 'react';
import client, { createAdhoc } from '../feathers';
import { format, isAfter, parseISO } from 'date-fns'
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { useSite } from '../Persistence/SiteContext';
import { useEmployeeStatus } from '../Persistence/EmpoyeeStatusContext';
import { useCaptureTypeList } from '../Persistence/CaptureTypeContext';
import { useCurrentUser, useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import './EmployeeStatus.scss'

export const EmployeeStatus = ({ employee }) => {

    const [employeeStatus, setEmployeeStatus] = useState();
    const [available,setAvailable] = useState(false);

    const siteList = useSite();
    const employeeStatusList = useEmployeeStatus();
    const captureTypeList = useCaptureTypeList();
    const currentUser = useCurrentUser();
    const isAdmin = useCurrentUserIsAdmin();

    useEffect(() => {
        if(employee && employeeStatusList){
            const status = employeeStatusList.find((_status) => _status.id === employee.id);
            if(status){
                status.captureActivity.captureType = captureTypeList.find((e) => e.id === status.captureActivity.captureTypeId);
                setEmployeeStatus(status);
                setAvailable(status.available)
            }
        }
    }, [employeeStatusList,captureTypeList,employee])

    const handleClickButton = () => {
        createAdhoc(employeeStatus.id, !available);
    }

    const renderButton = () => {
        return <div className="p-d-flex p-jc-center selectbutton">
            <SelectButton className={available?'selectbutton-true':'selectbutton-false'} value={available} options={[{name: 'verfügbar', value: true},{name: 'abwesend', value: false}]} onChange={() => handleClickButton()} optionValue={'value'} optionLabel={'name'}/>
            </div>
    }
    const handleClickActivity = (id, available, terminalId) => {
        const captureActivityService = client.service('capture-activity');
        const timestamp = new Date();
        const tagId = "TEST-Tagid";
        const idOnTerminal = 0;
        const refId = 0;
        const updatedBy = currentUser.id;
        const captureTypeId = id;
        const employeeId = employee.id;
        const captureActivity = { available, timestamp, tagId, employeeId, captureTypeId, refId, idOnTerminal, updatedBy, terminalId };
        captureActivityService.create(captureActivity);
    }

    const handleClickAlife = (terminalId) => {
        const terminalService = client.service('terminal');
        const timestampAlife = new Date();
        const terminal = {timestampAlife };
        terminalService.patch(terminalId,terminal);
    }

    const renderWebTerminalButtons = () => {
        const buttons = siteList.map((site) => {
            return (
                <div className="p-grid" key={'button_arrive_' + site.id}>
                    <div className="p-col employee-status-terminal-buttons">{'Terminal:' + site.alias}</div>
                    <div className="p-col"><Button className="employee-status-terminal-buttons" label='Kommen' id={'button_arrive_' + site.id} onClick={() => handleClickActivity(2, true, "Web-Terminal-" + site.alias)} /></div>
                    <div className="p-col"><Button className="employee-status-terminal-buttons" label='Gehen' id={'button_leave_' + site.id} onClick={() => handleClickActivity(3, false, "Web-Terminal-" + site.alias)} /></div>
                    <div className="p-col"><Button className="employee-status-terminal-buttons" label='aLife' id={'button_alife_' + site.id} onClick={() => handleClickAlife("Web-Terminal-" + site.alias)} /></div>
               </div>

            )
        })
        return <div className="p-flex p-m-20" style={{ marginTop: '20px' }}>{buttons}</div>
    }

    const renderStatus = () => {
        if (!employeeStatus) {
            return <h1>Ihr Status konnte nicht ermittelt werden.</h1>
        }
        return (
            <div className="employee-status p-col-fixed">
                <div className="p-d-flex p-jc-center">
                    <div className='p-shadow-24' >
                        <Card className='employee-status-card' titles={available ? 'Ich bin verfügbar' : 'Ich bin NICHT verfügbar'} footer={renderButton()}>
                            <div className="employee-status-image p-d-flex p-jc-center" onClick={() => handleClickButton()}>
                                <img alt='' src={available ? '../icons/03_BG_status-ON.png' : '../icons/03_BG_status-OFF.png'} />
                            </div>
                            <div className="p-d-flex p-pb-2" >{!isAdmin?'':(employeeStatus.captureActivity.captureType ? employeeStatus.captureActivity.captureType.name + ' ' + format(parseISO(employeeStatus.captureActivity.timestamp), 'HH:mm:ss') + ' - ' + employeeStatus.captureActivity.terminal.name : '<KEINE aktuelle Erfassung am Terminal>')}</div>
                            <div className="p-d-flex" >Aktueller Zeiteintrag:</div>
                            <div className="p-d-flex" ><b>{employeeStatus.timeEntry.timeEntryCategory.name}</b> &nbsp; {employeeStatus.timeEntry.title}</div>
                            <div className="p-d-flex p-pb-2" >{format(parseISO(employeeStatus.timeEntry.start), 'dd MMM HH:mm')}<b>&nbsp;bis&nbsp;</b>{format(parseISO(employeeStatus.timeEntry.end), 'dd MMM HH:mm')}</div>
                            {employeeStatus.adhoc && employeeStatus.adhoc.createdAt && isAfter(parseISO(employeeStatus.adhoc.createdAt),parseISO(employeeStatus.timeEntry.start))?
                                <div className="p-flex">
                                    <div >Adhoc-Eintrag:</div>
                                    <div >{format(parseISO(employeeStatus.adhoc.createdAt), 'dd MMM HH:mm:ss') + ' = ' + (employeeStatus.adhoc.available ? 'verfügbar' : 'NICHT verfügbar')}</div>
                                </div>
                            :''}
                        </Card>
                    </div>
                </div>
                {!isAdmin?'':<div className='employee-status-terminal-buttons'>{renderWebTerminalButtons()}</div> }      
            </div>)
    }

    return (
        <>
            {renderStatus()}
        </>
    )

}