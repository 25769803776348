import React, {useContext , useState, useEffect} from "react";
import { getData, updateListEntryInProvider ,updateServiceListener} from '../feathers'
import {LoggerContext} from "../Utils/logger";

const EmployeeContext = React.createContext();
const EmployeeUpdateContext = React.createContext();

const servicename = 'employee';
const listenernames = ['patched','updated'];
const providername = 'EmployeeProvider';

export function useEmployee(){
    return useContext(EmployeeContext);
}
export function useEmployeeUpdate(){
    return useContext(EmployeeUpdateContext);
}

export function EmployeeProvider({children}){
    const [list, setList] = useState([]);
    const [listItemChanged,setListItemChanged] = useState();

    useEffect(()=>{
        if(listItemChanged){
            updateListEntryInProvider(providername,listItemChanged,list,setList,servicename,true)
            setListItemChanged(null);
        }
    },[listItemChanged,list])

    useEffect(()=>{
        LoggerContext.debug('########### ' + providername + ' useEffect update');
        updateServiceListener(servicename,listenernames,(_listentry) => setListItemChanged(_listentry));    
    },[list])

    useEffect(()=>{
        LoggerContext.debug('########### ' + providername + ' useEffect');
        getData(servicename).then((_list)=>{
            LoggerContext.info(`########### ${providername} list: ${JSON.stringify(_list)}`)
            setList(_list);
        }).catch((error) => {LoggerContext.error("Error " + providername,error)});
        return () => {updateServiceListener(servicename,listenernames,null); }
    },[])

    return (
        <EmployeeContext.Provider value={list}>
            {children}
        </EmployeeContext.Provider>
    )
}