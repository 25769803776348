import React, { useState, useEffect , useRef} from 'react';
import { FullCalendar } from 'primereact/fullcalendar';
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin  from '@fullcalendar/timegrid';
import interactionPlugin  from '@fullcalendar/interaction';
import { Toast } from 'primereact/toast';
import { isAfter } from 'date-fns';
import { getPersonsStandBy, updateStandByEntry, getStandByEntries} from '../feathers';
import { Button } from 'primereact/button';
import logger from '../Utils/logger';
import { useCurrentUser } from '../Persistence/CurrentUserContext';

var calendarRef = React.createRef();

export const CalendarEVD = () =>{

    const [ standby,setStandby] = useState({listPersons:[]});
    const [ refresh,setRefresh] = useState(false);
    const toast = useRef(null);
    const currentUser = useCurrentUser();

    var selectedEvent;
    var selectStart;
    var selectEnd;
    var oneClickTimer;
    let clickCnt = 0;


    const options = {
        height: '600px',
        contentHeight: '400px',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        defaultView: 'timeGridWeek', initialView: 'timeGridWeek',

        eventClick: async (info) =>  {
            clickCnt++;
            if (clickCnt === 1) {
                logger.debug('eventClick:',info.event)
                if(selectedEvent){
                    selectedEvent.setProp( 'borderColor','black');
                    selectedEvent.setProp( 'backgroundColor',selectedEvent.extendedProps.backcolor + 'CC');
                }
                selectedEvent = info.event;
                selectStart = info.event.start;
                selectEnd = info.event.end;
                info.event.setProp( 'borderColor','red');
                info.event.setProp( 'backgroundColor',info.event.extendedProps.backcolor);
                oneClickTimer = setTimeout(() => clickCnt = 0, 400);
            } else if (clickCnt === 2) {
                clearTimeout(oneClickTimer);
                clickCnt = 0;
                //setDisplay(true);
            }  
        },

        select: (info) => {
            logger.debug("select:" + info.startStr + " - " + info.endStr,info);
            if(selectedEvent){
                selectedEvent.setProp( 'borderColor','black');
                selectedEvent = null;
            }

            selectStart = info.start;
            selectEnd = info.end;
            //setSelectStart(info.start);
            //setSelectEnd(info.end);
        },
        eventContent: (info) =>{
           // logger.debug('eventContent' + info.e1,info.view);
        },
        locales: [ deLocale ],
        weekNumbers:true,
        eventDisplay:'block',
        slotDuration:'01:00:00',
        slotLabelInterval:"02:00:00",
        eventBorderColor:'rgb(255,255,255)',
        slotMinTime:'00:00:00',
        slotMaxTime:'24:00:00',
        nextDayThreshold:'09:00:00',
        dayHeaderFormat:{ weekday: 'short', day: 'numeric', omitCommas: true },
        slotLabelFormat: [{ hour: 'numeric' }],
        allDaySlot:false,
        slotEventOverlap:false,
        selectable: true,
        displayEventEnd:false,displayEventTime:false,
        headerToolbar: {
            center: 'title',
            right: 'prev,next,today',
            left: 'dayGridMonth,timeGridWeek' // buttons for switching between views
        },

        
        editable: false,
        navLinks: false,
        events: function (info, successCallback, failureCallback) { 
            getStandbyEntries(info).then((events) =>{
                logger.debug('events:',events);
                successCallback(events);
            }).catch((error) => {
                failureCallback(error);
            });
           
        },
    };

    const getStandbyEntries =  async (info) =>{
        const events =  await getStandByEntries(1,info.start,info.end).then((listEntries)=>{
            logger.debug("standby Entries: ",listEntries)
            return listEntries.map((entry)=>{
                const person = standby.listPersons.find(e => e.employee.id === entry.employee.id)
                const backgroundColor = (person?person.backcolor + 'AA':'red');
                const backcolor = (person?person.backcolor:'red');
                const textColor = (person?person.color:'white');
                const title = entry.employee.firstname.substring(0,1) + '. ' + entry.employee.lastname;
                const standby_service_id = entry.id
                return {...entry,textColor,backgroundColor,backcolor,title,standby_service_id} 
            })
        });
        return events;
    }

    useEffect(()=>{
        getPersonsStandBy(1).then((standby)=>{
            setStandby(standby);
            setRefresh(true);
        })
    },[])

    const createCalendarEntry = async (employee) =>{
        if( isAfter(new Date(),selectEnd)){
            toast.current.show({severity:'error', summary: 'Fehler: Ende Datum', detail:'Ende Datum liegt in der Vergangenheit', life: 2000});
        } else if(selectStart && selectEnd && employee){
            await updateStandByEntry(standby,selectStart,selectEnd,employee);
            setRefresh(!refresh)
        } else{
            toast.current.show({severity:'error', summary: 'Fehler', detail:'Kein Eintrag oder neues Datum ausgewählt', life: 2000});
        }
    }

    const renderPersonButtons = () =>{
        if (!currentUser.roles.includes('standby-edit')) {
            return '';
        }
        return standby.listPersons.map( p => 
            <div className="p-mr-2">
                <Button style={{color:p.color, backgroundColor:p.backcolor}} onClick={() => createCalendarEntry(p.employee)} label={p.employee.firstname.substring(0,1) + '. '+ p.employee.lastname}/>
            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} position="bottom-left"  />
             <div className="p-d-flex p-flex-column p-m-3 p-p-3 p-shadow-12">
                <div className="p-mb-2"><FullCalendar id='calendarEdv_fullcalendar' options={options}  ref={calendarRef} style={{width:'550px'}}/></div>
                <div className="p-mb-2">
                    <div className="p-d-flex p-jc-center">
                        {renderPersonButtons()}
                    </div>
                </div>
            </div>
         </>
    );
}