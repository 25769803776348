import { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { getOpenTags, patchData } from '../feathers';
import { SelectButton } from 'primereact/selectbutton';
import { useEmployee } from '../Persistence/EmployeeContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext'
import './ManageSimpleListPage.scss'
import logger from '../Utils/logger';


export const TagPage = () => {
    const [id, setId] = useState();
    const [active, setActive] = useState();
    const [tagId, setTagId] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [list, setList] = useState([]);
    const listEmployee = useEmployee();
    const currentUser = useCurrentUser();

    const op = useRef(null);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            op.current.hide();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isMounted.current = true;
        //getData("tag").then((_list)=>{
        getOpenTags().then((_list)=>{    
            setList(_list);
            logger.debug(`list:${JSON.stringify(_list)}`)
        })
    }, [listEmployee])


    const handleChangeSelectedValue = (value) => {
        setSelectedValue(value);
        setId(value.id);
        setTagId(value.tagId);
        setActive(value.active)
    }

    const handleOnClickSaveEntry = async () => {
        await patchData('tag', { id,employeeId:selectedEmployee.id,updatedBy:currentUser.id});
        await patchData('employee', { id:selectedEmployee.id });    
        setSelectedEmployee(null);
        handleChangeSelectedValue({})
        op.current.hide();
    }

    const renderSelectButton = (rowData) => {
        return <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={(e) => { op.current.toggle(e); handleChangeSelectedValue(rowData) }} aria-haspopup aria-controls="tag_overlay_panel" ></Button>
    }
    const renderStatus = (rowData) => {
        return <i className={rowData.active?"pi pi-check":"pi pi-times"} style={{'fontSize': '1em'}}></i>
    }

    return (
        <>
            <div className="p-fluid p-p-4" id='manage-simple-list-page' style={{ width: '700px' }}>
                <div className="p-d-flex p-jc-center ">
                    <h3>nicht zugeordnete Tags</h3>
                </div>
                <div className="p-grid">
                   <div className="p-col">
                        <div className="p-shadow-12">
                            <DataTable value={list} dataKey="id" className="p-datatable-sm" selection={selectedValue} onSelectionChange={e => handleChangeSelectedValue(e.value)} >
                                <Column body={renderSelectButton} headerStyle={{ width: '100px' }}></Column>
                                <Column body={renderStatus} header="Status" headerStyle={{width: '60px' }}></Column>
                                <Column field="tagId" header="Tag-ID"  ></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <OverlayPanel dismissable={false} ref={op} showCloseIcon id="tag_overlay_panel" style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
                    <div className="p-grid" style={{ width: '600px' }} id='manage-simple-list-page-panel'>
                        <div className="p-col-fixed"  style={{ width: '150px' }}>
                            <div className="p-fluid" style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
                                <div className="p-field ">
                                    <label htmlFor="status" className="p-d-block" >Status</label>
                                    <SelectButton value={active} optionLabel='name' optionValue='value' options={[{name:'aktiv',value:1}, {name:'inaktiv',value:0}]} onChange={(e) => setActive(active===0?1:0)} />
                                </div>
                                <div className="p-field ">
                                    <label htmlFor="name" className="p-d-block" >Tag-ID</label>
                                    <InputText disabled={true} autoComplete="nope" value={tagId} id="newName" aria-describedby="name-help" className={(tagId === '' ? 'p-invalid' : '') + " p-d-block"} />
                                </div>
                                <div className="p-d-flex">
                                    <Button className='p-button-success button p-m-2' onClick={handleOnClickSaveEntry} disabled={selectedEmployee === null} label='Zuordnen' />
                                </div>
                            </div>
                        </div>
                        <div className="p-col">
                            <DataTable scrollable scrollHeight={'400px'}  value={listEmployee} dataKey="id" className="p-datatable-sm" selection={selectedEmployee} onSelectionChange={e => setSelectedEmployee(e.value)} >
                                <Column field="lastname" headerStyle={{display:'none'}} bodyStyle={{display:'none',width: '0px'}} filter filterPlaceholder="Suche nach Name" ></Column>
                                <Column selectionMode="single"  headerStyle={{display:'none'}} bodyStyle={{width: '2em'}}></Column>
                                <Column body={(rowData) => `${rowData.lastname}, ${rowData.firstname}`} headerStyle={{display:'none'}} ></Column>
                            </DataTable>
                        </div>
                    </div>
                </OverlayPanel>
        </>
    )

}