import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns'
import { useEmployeeStatus } from '../Persistence/EmpoyeeStatusContext';
import { useQualificationFunction } from '../Persistence/QualificationFunctionContext';
import { useSite } from '../Persistence/SiteContext';
import logger from '../Utils/logger';
import './OperatorView.scss'
import { AvailabilityPreview } from './AvailabilityPreview';
import { EmployeeProvider } from '../Persistence/EmployeeContext';


const timeline_1 = 600;
const timeline_2 = 900;

const bodyDataPresent = (site, count) => {
	return (
		<div className="p-d-flex p-jc-center" style={{ width: '100%' }}>
			<div style={{ width: '50px' }}>{site}</div>
			<div style={{ width: '20px' }}>{count}</div>
		</div>
	);
}

const bodyDataAvailable = (site, entry) => {
	const cell = <div key={'div-cell' + site.id} className={"p-d-flex p-jc-center " + entry.className} >
		<div key={'div-cell-site'} style={{ width: '50px' }}>{site}</div>
		<div key={'div-cell-total'} style={{ width: '20px' }}>{entry.total}</div>
	</div>;
	var quals = '';
	if (entry.qualFunction != null) {
		quals = Object.getOwnPropertyNames(entry.qualFunction).map((prop) => {
			return <div key={'div-cell-' + prop} className="p-d-flex p-jc-center qualifications">
				<div key={'div-cell-site-' + prop} style={{ width: '50px' }}>{prop}</div>
				<div key={'div-cell-total-' + prop} style={{ width: '20px' }}>{entry.qualFunction[prop]}</div>
			</div>
		});
	}
	return (<div key={'div-site-' + site.id}>
		{cell}
		{quals}
	</div>
	);
}

const headerDataPresent = (site, count) => {
	return (
		<div className="p-d-flex p-jc-center" style={{}}>
			<div>{site + ':' + count}</div>
		</div>
	);
}

const headerDataAvailable = ({ name, count, start, end }) => {
	return (
		<div className="p-d-flex p-flex-column" style={{ width: '100%' }}>
			<div className="p-d-flex p-jc-center">{"Radius " + name}</div>
			<div className="p-d-flex p-jc-center" style={{ fontWeight: '100' }}>{"[ " + (!end ? '> ' + start : (!start ? '< ' + end : start + '-' + end)) + ' ] MIN'}</div>
			<div className="p-d-flex p-jc-center">{"verfügbar: " + count}</div>
		</div>
	);
}
const headerAvailable = [
	{ name: '1', count: 0, start: undefined, end: timeline_1 / 60 },
	{ name: '2', count: 0, start: timeline_1 / 60, end: timeline_2 / 60 },
	{ name: '3', count: 0, start: timeline_2 / 60, end: undefined },
]

const _operatorViewData = {
	totalCountPresent: 0,
	totalCountAvailable: 0,
	headerPresent: [],
	dataPresent: [],
	dataAvailable: [],
	headerAvailable: JSON.parse(JSON.stringify(headerAvailable)),
	evd: null
}

const writeQualification = (qualification_functions, data) => {
	if (qualification_functions) {
		qualification_functions.forEach((qualFunction) => {
			if (data.qualFunction.hasOwnProperty(qualFunction.alias)) {
				data.qualFunction[qualFunction.alias]++
			}
		})
	}
}
let timeInterval;
export const OperatorViewCorona = () => {

	const [currentTime, setCurrentTime] = useState(new Date());
	const [operatorViewData, setOperatorViewData] = useState(_operatorViewData)

	const siteList = useSite();
	const qualificationFunctionList = useQualificationFunction();
	const employeeStatusList = useEmployeeStatus();

	const actualizeStateVariables = useCallback(async () => {
		try {
			const _qualFunctionList = qualificationFunctionList.filter((f) => f.operationView === 1);
			_qualFunctionList.sort((a, b) => a.sort - b.sort)

			var evd = null;
			var totalCountPresent = 0;
			var totalCountAvailable = 0;
			const headerPresent = siteList.map((site) => {
				return { siteId: site.id, name: site.alias, count: 0 }
			});

			const _headerAvailable = JSON.parse(JSON.stringify(headerAvailable));
			const dataPresent = _qualFunctionList.map((qualFunction) => {
				const { id, alias, display } = qualFunction;
				const _dataPresent = { id, alias, display, count: 0 }
				siteList.map(site => _dataPresent[site.alias] = 0);
				return _dataPresent;
			});

			const _qualFunctionsAvailable = {};
			_qualFunctionList.forEach((qualFunction) => {
				_qualFunctionsAvailable[qualFunction.alias] = 0
			});

			var siteID_AA = 0;

			const dataAvailable = siteList.map((site) => {
				const _dataAvailable = { siteId: site.id, sort: site.sort, name: site.alias, count_1: { total: 0, className: 'summary', qualFunction: { ..._qualFunctionsAvailable } }, count_2: { total: 0, className: 'summary', qualFunction: { ..._qualFunctionsAvailable } }, count_3: { total: 0, className: 'summary', qualFunction: { ..._qualFunctionsAvailable } } };
				if (site.alias === 'AA') {
					siteID_AA = site.id;
				}
				return _dataAvailable
			});
			const entryAvailableOKO = { siteId: 0, sort: 0, name: 'OKO', count_1: { total: 0, className: 'summaryOKO' }, count_2: { total: 0, className: 'summaryOKO' }, count_3: { total: 0, className: 'summaryOKO' } }
			dataAvailable.push(entryAvailableOKO)

			await employeeStatusList.forEach(async (employeeStatus) => {
				const { captureActivity, available, present, employee, timeEntry } = employeeStatus;
				//logger.debug("---- ",{id,active:employee.active,available, present})
				if (employee.active) {              /** nur aktiv Mitarbeiter auswerten */
					const { qualification_functions } = employee;
					if (present) {                  /** Mitarbeiter ist als anwesend an einem Terminal registriert */
						const { terminal } = captureActivity;
						var entry = headerPresent.find(e => e.siteId === terminal.siteId);
						if (!entry) {
							logger.error("captureActivity without site:", captureActivity)
						} else {
							entry.count++;
						}

						totalCountPresent++;

						if (qualification_functions) {
							qualification_functions.forEach((qualFunction) => {
								const temp = dataPresent.find(q => q.id === qualFunction.id);
								if (temp) {
									temp[entry.name]++
								}
							})
						}
					} else if (available) {         /** Mitarbeiter ist NICHT anwesend aber auf Basis seiner Zeiteinträge verfügbar */
						totalCountAvailable++;

						/** site ermitteln und dem richtigen Radius zuordnen */
						var entryAvailable = dataAvailable.find(e => e.siteId === employee.siteId);
						if (entryAvailable) {
							if (timeEntry.secondsToArrive <= timeline_1) {
								entryAvailable.count_1.total++;
								writeQualification(qualification_functions, entryAvailable.count_1);
								_headerAvailable[0].count++
								if (siteID_AA !== employee.siteId) {
									entryAvailableOKO.count_1.total++;
								}
							} else if (timeEntry.secondsToArrive <= timeline_2) {
								entryAvailable.count_2.total++;
								writeQualification(qualification_functions, entryAvailable.count_2);
								_headerAvailable[1].count++
								if (siteID_AA !== employee.siteId) {
									entryAvailableOKO.count_2.total++;
								}
							} else {
								entryAvailable.count_3.total++;
								writeQualification(qualification_functions, entryAvailable.count_3);
								_headerAvailable[2].count++
								if (siteID_AA !== employee.siteId) {
									entryAvailableOKO.count_3.total++;
								}
							}
						}
					}
					/** EvD Prüfung */
					if (employeeStatus['standby_1']) {
						evd = employeeStatus.employee
					}
				}
			})
			siteList.forEach((site) => {
				dataPresent.forEach((e) => {
					e[site.alias] = bodyDataPresent(e.alias, e[site.alias]);
				})
			});

			dataAvailable.forEach(e => {
				e['count_1'] = bodyDataAvailable(e.name + ":", e['count_1']);
				e['count_2'] = bodyDataAvailable(e.name + ":", e['count_2']);
				e['count_3'] = bodyDataAvailable(e.name + ":", e['count_3']);
			})

			dataAvailable.sort((a, b) => a.sort - b.sort)

			setOperatorViewData({
				totalCountPresent,
				totalCountAvailable,
				headerPresent,
				dataPresent,
				dataAvailable,
				headerAvailable: _headerAvailable,
				evd
			})
			logger.debug("actualizeStateVariables headerPresent:", headerPresent);
			logger.debug("actualizeStateVariables dataPresent:", dataPresent);


		} catch (error) {
			// If we got an error, wait and do nothing
		}
	}, [employeeStatusList, siteList, qualificationFunctionList]);

	useLayoutEffect(() => {
		timeInterval = window.setInterval(() => { setCurrentTime(new Date()) }, 60000);
		return () => { window.clearInterval(timeInterval) }
	}, []);

	useEffect(() => {
		if (employeeStatusList && siteList.length > 0 && qualificationFunctionList.length > 0) {
			actualizeStateVariables();
		}
	}, [actualizeStateVariables, employeeStatusList, siteList, qualificationFunctionList])

	const renderColumnsPresent = () => {
		return (operatorViewData.headerPresent.map((col) => {
			return <Column key={col.name} field={col.name} headerClassName='operatorView_header' className='operatorView_content_corona' header={headerDataPresent(col.name, col.count)} />;
		}));
	}

	const renderColumnsAvailable = () => {
		return (operatorViewData.headerAvailable.map((col) => {
			return <Column key={col.name} field={'count_' + col.name} headerClassName='operatorView_header' className='operatorView_content_corona' header={headerDataAvailable(col)} />;
		}));
	}

	return (
		<div className="p-col-fixed" style={{ width: '100%' }}>

			<div className="p-d-flex p-jc-center" style={{ backgroundColor: 'rgba(255,255,255,0)' }}>

				<div className="p-p-2 p-p-md-4" >
					<div className="operatorView p-d-flex p-jc-center p-flex-column p-shadow-12 p-p-2 p-p-md-4 ">
						<div className='p-d-flex p-jc-center'>
							<div className='operatorView_time'>
								{format(currentTime, "HH:mm")}
							</div>
						</div>
						<div className='p-p-2 operatorView_evd p-d-flex p-flex-column' >
							<div className='p-d-flex p-jc-between'>
								<div>EvD</div>
								<div className='operatorView_evd_content p-d-flex p-jc-end'>{operatorViewData.evd ? operatorViewData.evd.firstname.substring(0, 1) + ". " + operatorViewData.evd.lastname : 'n.n.'}</div>
							</div>
							<div className='p-d-flex  p-jc-between'>
								<div>{format(currentTime, "'KW' ww")}</div>
								<div className='operatorView_evd_content p-d-flex  p-flex-column'>
									<div className='p-d-flex p-jc-end '>{operatorViewData.evd && operatorViewData.evd.phone ? operatorViewData.evd.phone : 'n.n.'}</div>
									<div className='p-d-flex p-jc-end '>{operatorViewData.evd && operatorViewData.evd.mobile ? operatorViewData.evd.mobile : 'n.n.'}</div>
								</div>
							</div>
						</div>

						<div className='operatorView-datatable p-pb-4 p-d-none'>
							<DataTable
								header={<div className="p-d-flex p-jc-center"><div>{'Aktuell anwesend: ' + operatorViewData.totalCountPresent}</div></div>}
								value={operatorViewData.dataPresent}
								className="p-datatable-header p-datatable-sm">
								{renderColumnsPresent()}
							</DataTable>
						</div>

						<div className='operatorView-datatable '>
							<DataTable
								className='p-datatable-header p-datatable-sm'
								header={<div className="p-d-flex p-jc-center"><div>{'Aktuell verfügbar: ' + operatorViewData.totalCountAvailable}</div></div>}
								value={operatorViewData.dataAvailable}>
								{renderColumnsAvailable()}
							</DataTable>
						</div>
					</div>
				</div>
				<div className="p-p-2 p-p-md-4" >
					<div className="p-d-flex p-jc-center p-flex-column p-shadow-12 p-p-2 p-p-md-4 ">
						<EmployeeProvider>
							<AvailabilityPreview />
						</EmployeeProvider>
					</div>
				</div>
			</div>
		</div>
	)
}