import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { RadioButton } from 'primereact/radiobutton';
import { useCallback, useEffect, useState } from "react"
import { InputChangesChecked } from '../Components/InputChangesChecked';
import { InputTextareaChangesChecked } from '../Components/InputTextareaChangesChecked';
import { renderMinutesAsString, TimePickerChecked } from '../Components/TimePickerChecked';
import client, { getData, patchData } from "../feathers";
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useTimeEntryCategoryList } from '../Persistence/TimeEntryCategoryListContext';

const emptyTemplate = { id: null, version: 1, active: 1, sort: 1000, forecolor: '', backcolor: '', timeRange: [480, 960], timeEntryCategoryId: null, description: '', name: '', timeEntryTemplateItemsId: '', itemVersion: '', itemActive: '' }

export const EmployeeTimeEntryTemplate = ({ employee }) => {

	const currentUser = useCurrentUser();
	const timeEntryCategories = useTimeEntryCategoryList();
	const [timeEntryTemplates, setTimeEntryTemplates] = useState();
	const [displayDialog, setDisplayDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState(true);
	const [showDialog, setShowDialog] = useState(false);


	useEffect(() => {
		getUserTemplates();
	}, [employee])

	const formik = useFormik({

		initialValues: { ...emptyTemplate },
		validate: (data) => {
			let errors = {};
			if ((data.timeRange[1] - data.timeRange[0]) * 5 < 30) {
				errors.timeRange = 'Der Zeitraum muss mindestens 30min sein';
			}
			if (!data.name) {
				errors.name = 'Name muss ausgefüllt sein';
			}
			if (!data.timeEntryCategoryId) {
				errors.timeEntryCategoryId = 'Kategorie muss angegeben werden';
			}
			const hasErrors = Object.keys(errors).length !== 0 ? data : null
			setFormHasErrors(hasErrors);

			return errors;
		},
		enableReinitialze: true,
		validateOnMount: true
	});

	const getUserTemplates = useCallback(async () => {
		const service = client.service('time-entry-templates');
		const query = { employeeId: employee.id }
		console.log('employee: ', employee, query)
		return service.find({ query }).then(result => {
			for (const template of result) {
				if (template.timeEntryTemplateItems) {
					for (const item of template.timeEntryTemplateItems) {
						template.timeEntryTemplateItemsId = item.id;
						template.timeEntryCategoryId = item.timeEntryCategoryId;
						template.timeRange = [parseInt(item.start), parseInt(item.end)];
						template.weekdays = item.weekdays;
						template.itemActive = item.active;
						template.itemVersion = item.version;
					}
				}
			}
			setTimeEntryTemplates(result);
		});
	}, [employee])

	const patchTimeEntryTemplate = async () => {
		const { id, version, active, name, description, sort, forcolor, backcolor, timeEntryCategoryId, timeRange, timeEntryTemplateItemsId, itemVersion, itemActive } = formik.values;
		const template = { id, version, active, name, description, sort, forcolor, backcolor, employeeId: employee.id };

		await patchData('time-entry-templates', template, currentUser).then(async (template) => {
			const templateItem = { id: timeEntryTemplateItemsId, active: itemActive, version: itemVersion, timeEntryCategoryId, start: timeRange[0], end: timeRange[1], timeEntryTemplateId: template.id }
			await patchData('time-entry-template-items', templateItem, currentUser).then(() => {
				getUserTemplates();
				setDisplayDialog(false);
				formik.setValues({ ...emptyTemplate });
			})
		})
	}

	const handleSelectTemplate = async (template) => {
		console.log('template ', template);
		formik.setValues(template);
		setDisplayDialog(true);
	}

	const renderEdit = (rowData) => {
		return <div className="flex justify-content-center w-full" onClick={() => { handleSelectTemplate(rowData) }}>
			<i className='fa fa-pencil-square-o' />
		</div>
	}

	const renderCategory = (rowData) => {
		const timeEntryCategoryList = (!timeEntryCategories ? [] : timeEntryCategories);
		const category = timeEntryCategoryList.find(entry => entry.id === rowData.timeEntryTemplateItems[0].timeEntryCategoryId)
		return <div style={{ color: (category.available ? 'lightgreen' : 'red') }}>{category.name}</div>
	}

	const renderTimeEntryCategory = () => {
		const timeEntryCategoryList = (!timeEntryCategories ? [] : timeEntryCategories);
		const listAvailable = [];
		const listNotAvailable = [];

		const handleChangeTimeEntryCategory = (e) => {
			formik.setFieldValue('timeEntryCategoryId', e.value.id)
		}

		timeEntryCategoryList.forEach(category => {
			if (category.available) {
				listAvailable.push(category);
			} else {
				listNotAvailable.push(category);
			}
		});
		return (<>
			<div className="p-my-3">
				<label>Kategorie</label>
			</div>
			<div className="employee-time-entry-template-fieldset p-grid">
				<div className=" p-col">
					<Fieldset legend="verfügbar">
						{listAvailable.map((category) => {
							return <div key={category.id} className="p-field-radiobutton">
								<RadioButton id={category.id} name="category" value={category} onChange={handleChangeTimeEntryCategory} checked={formik.values['timeEntryCategoryId'] === category.id} />
								<label style={category.available ? { color: '#9fdaa8' } : { color: '#f19ea6' }} htmlFor={category.id}>{category.name + ' (' + category.timeEntryCategoryType.name + ')'}</label>
							</div>
						})}
					</Fieldset>
				</div>
				<div className=" p-col">
					<Fieldset legend="nicht verfügbar">
						{listNotAvailable.map((category) => {
							return (<div key={category.id} className="p-field-radiobutton">
								<RadioButton id={category.id} name="category" value={category} onChange={handleChangeTimeEntryCategory} checked={formik.values['timeEntryCategoryId'] === category.id} />
								<label style={category.available ? { color: '#9fdaa8' } : { color: '#f19ea6' }} htmlFor={category.id}>{category.name}</label>
							</div>)
						})}
					</Fieldset>
				</div>
			</div>
		</>
		)
	}

	return (<div className='p-d-inline-flex p-flex-column p-jc-center ' style={{ width: '100%' }}>

		<Dialog position="center" className='p-p-0' header={formik.values.id ? 'Vorlage bearbeiten' : 'Neue Vorlage'} visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-timeentrytemplate" style={{ width: '450px' }} >
			<div className='p-p-2'>
				<div className="p-grid ">
					<div className="p-fluid p-field p-col p-m-0">
						<div className=" p-grid">
							<InputChangesChecked id={'name'} label='Name' editmode={true} formik={formik} type='text' setShowDialog={setShowDialog} key='name' />
						</div>
						<div className="p-fluid p-formgrid p-grid">
							<TimePickerChecked id={'timeRange'} labelStart='Startzeit' labelEnd='Endezeit' editmode={true} formik={formik} setShowDialog={setShowDialog} key='timeRange' steps={15} />
						</div>
						{renderTimeEntryCategory()}
						<div className="p-fluid p-formgrid p-grid p-mt-3 p-mb-1">
							<InputTextareaChangesChecked id='description' label='Beschreibung' editmode={true} rows={2} formik={formik} setShowDialog={setShowDialog} key='desription' />
						</div>
					</div>
				</div>
				<div className="p-d-flex p-jc-end">
					<Button label="Abbrechen" onClick={() => { setDisplayDialog(false) }} />
					<Button onClick={patchTimeEntryTemplate} disabled={formHasErrors} className=" p-ml-3" label={formik && formik.values.id ? 'Vorlage ändern' : 'Vorlage anlegen'} />
				</div>
			</div>
		</Dialog>
		<div className="p-mx-auto p-p-2">
			<div className="p-d-flex">
				<Button onClick={() => { formik.setValues({ ...emptyTemplate }); setDisplayDialog(true) }} className='p-d-flex' label='Neue Vorlage anlegen' />
			</div>
		</div>
		<div className="p-mx-auto p-mb-auto" style={{ maxWidth: '1000px', backgroundColor: 'rgba(0,0,0,0.2)' }}>
			<DataTable emptyMessage='keine Einträge gefunden' scrollHeight={'calc(100vh - 150px)'} value={timeEntryTemplates} showGridlines stripedRows size="small" filterDisplay='row' responsiveLayout="scroll" dataKey="id" >
				<Column style={{ width: '100px' }} body={renderCategory} header="Kategorie"></Column>
				<Column style={{ width: '200px' }} field="name" header="Name"></Column>
				<Column field="description" header="Beschreibung"></Column>
				<Column style={{ width: '100px' }} body={(rowData) => { return renderMinutesAsString(rowData.timeRange[0]) }} header="Startzeit"></Column>
				<Column style={{ width: '100px' }} body={(rowData) => { return renderMinutesAsString(rowData.timeRange[1]) }} header="Endezeit"></Column>
				<Column style={{ width: '40px' }} className='open-icon' body={renderEdit}></Column>
			</DataTable>
		</div >
	</div >)
}