import React, { useState, useEffect } from 'react';
import { parseISO, differenceInSeconds, addSeconds , isBefore, format, addHours, differenceInDays} from 'date-fns'
import { useTerminal } from '../Persistence/TerminalContext';

export const TerminalStatus = () => {

    const terminalList = useTerminal();
    const [statusDate, setStatusDate] = useState(new Date());

    useEffect(()=>{       
        if(terminalList) {
            var oldestDate = new Date();  
            terminalList.forEach((terminal) => {      
                if(terminal.active === 1){
                    if(!terminal.timestampAlife){
                        terminal.timestampAlife = terminal.createdAt
                    }
                    const result = addSeconds(parseISO(terminal.timestampAlife),terminal.alifeInterval)
                    if(isBefore(result,oldestDate)){
                        oldestDate = result;
                    }
                }           
            });
            setStatusDate(oldestDate);
        }
    },[terminalList])

    const renderStatus = () => {
        const statusSeconds = differenceInSeconds(new Date(),statusDate)
        const displayDate = addHours( new Date(statusSeconds*1000),-25);
        const formatString = 'HH:mm:ss';
        const days = differenceInDays(new Date(),statusDate);
        const style = statusSeconds < 60?{color:'green','fontSize': '1.5em'}:{color:'red','fontSize': '1.5em'}
        return <div><span style={{marginRight:'10px'}}>{(days > 1? days + ' Tage ':days === 1? days + ' Tag ':'') + format( displayDate,formatString)}</span><i className={statusSeconds < 60?"pi pi-check":"pi pi-ban"} style={style}></i></div>;
    }

    return (
        <>
            <div className="p-col-fixed">
                <div className="p-d-flex p-jc-center">
                    {renderStatus()}
                </div> 
            </div>
        </>
    )

}