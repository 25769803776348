import { useRef, useState, useEffect } from 'react'
import { Menu } from 'primereact/menu';
import { PanelMenu } from 'primereact/panelmenu';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { logout } from '../feathers';
import { useMediaQuery } from '../Utils/useMediaQuery'
import { useCurrentUser } from '../Persistence/CurrentUserContext';


export const Sidemenu = ({ visibleMenu, setVisibleMenu, handleSidemenuCommand }) => {
	const toast = useRef(null);
	const [screenSize] = useMediaQuery();
	const [items, setItems] = useState();
	const currentUser = useCurrentUser();

	useEffect(() => {
		const items = [];
		if (currentUser.employee) {
			if (screenSize[0] > 1300) {
				items.push(
					{
						label: 'Mein Bereich', icon: 'pi pi-home',
						command: () => {
							handleSidemenuCommand('EmployeePage');
						}
					},
					{
						label: 'persönliche Daten', icon: 'pi pi-user',
						command: () => {
							handleSidemenuCommand('EmployeeForm');
						}
					},
					{
						label: 'Vorlagen Zeiteinträge', icon: 'pi pi-clock',
						command: () => {
							handleSidemenuCommand('EmployeeTimeEntryTemplate');
						}
					}
				)
			} else {
				items.push(
					{
						label: 'Mein Bereich', icon: 'pi pi-home',
						items: [
							{
								label: 'Status', icon: 'pi pi-home',
								command: () => {
									handleSidemenuCommand('EmployeeStatus');
								}
							},
							{
								label: 'Kalender', icon: 'pi pi-calendar',
								command: () => {
									handleSidemenuCommand('EmployeeCalendar');
								}
							},
							{
								label: 'persönliche Daten', icon: 'pi pi-user',
								command: () => {
									handleSidemenuCommand('EmployeeForm');
								}
							},
							{
								label: 'Vorlagen Zeiteinträge', icon: 'pi pi-clock',
								command: () => {
									handleSidemenuCommand('EmployeeTimeEntryTemplate');
								}
							}
						]



					}
				)
			}
			if (currentUser.roles !== '') {
				items.push({ separator: true })
			}

		}
		if (currentUser.roles.includes('operator-view')) {
			items.push({ label: 'Kräfteübersicht', icon: 'pi pi-lock', command: () => { handleSidemenuCommand('OperatorView') } });
			items.push({ label: 'Kräfteübersicht (verf.)', icon: 'pi pi-lock', command: () => { handleSidemenuCommand('OperatorViewCorona') } });
			items.push({ separator: true });
		}
		if (currentUser.roles.includes('hr')) {
			items.push({ label: 'Personal Stammdaten', icon: 'pi pi-users', command: () => { handleSidemenuCommand('EmployeesPage') } });
			items.push({ separator: true });
		}
		if (currentUser.roles.includes('report')) {
			items.push({ label: 'Auswertungen', items: [{ label: 'Alarm & Übung', icon: 'pi pi-chart-bar', command: (e) => { handleSidemenuCommand('OperationReport') } }] });
			items.push({ separator: true });
		}
		if (currentUser.roles.includes('standby-calendar')) {
			items.push({ label: 'EvD Kalender', icon: 'pi pi-calendar', command: () => { handleSidemenuCommand('CalendarEVD') } });
			items.push({ separator: true });
		}
		if (currentUser.roles.includes('config')) {
			const subItems = []
			subItems.push({ label: 'Org. Funktionen', icon: 'pi pi-cog', command: (e) => { handleSidemenuCommand('ManageOrganizationFunctions') } });
			subItems.push({ label: 'Qual. Funktionen', icon: 'pi pi-cog', command: (e) => { handleSidemenuCommand('ManageQualificationFunctions') } });
			subItems.push({ label: 'Unternehmensbereiche', icon: 'pi pi-cog', command: (e) => { handleSidemenuCommand('ManageBusinessUnits') } });
			if (currentUser.permissions.includes('bosc')) {
				subItems.push({ label: 'Standorte', icon: 'pi pi-cog', command: (e) => { handleSidemenuCommand('ManageSites') } });
				subItems.push({ label: 'Terminals', icon: 'pi pi-cog', command: (e) => { handleSidemenuCommand('ManageTerminals') } });
			}
			subItems.push({ label: 'Tags nicht zugeordnet', icon: 'pi pi-cog', command: (e) => { handleSidemenuCommand('openTags') } });
			items.push({ label: 'Konfiguration', icon: 'pi pi-cog', items: subItems });
		}
		if (currentUser.permissions.includes('users') || currentUser.permissions.includes('admin') || currentUser.permissions.includes('bosc')) {
			items.push({ separator: true });
			items.push({ label: 'Anmeldeuser', icon: 'pi pi-user', command: (e) => { handleSidemenuCommand('ManageUsers') } });
		}
		setItems(items)
	}, [currentUser, handleSidemenuCommand, screenSize])


	const itemsFooter = [
		{
			label: 'ausloggen',
			icon: 'pi pi-sign-out',
			command: (e) => { logout() }
		}
	]

	return (
		<>
			<Toast ref={toast}></Toast>

			<Sidebar visible={visibleMenu} onHide={() => setVisibleMenu(false)}  >
				<div className="p-d-flex p-mt-3 p-flex-column p-jc-between" style={{ height: screenSize[1] - 100 }}>
					<div className='p-shadow-24' style={{ backgroundColor: 'rgb(0,123,0,0.2)', width: '250px' }}>
						<Menu model={items} style={{ width: '250px' }} />
					</div>
					<div className="p-mr-2 p-as-start">
						<PanelMenu model={itemsFooter} style={{ width: '250px' }} />
					</div>
				</div>
			</Sidebar>
		</>
	)
}

