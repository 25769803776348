import { useState, useEffect } from 'react';
import { Slider } from 'primereact/slider';
import { startOfDay, addMinutes, format, getHours, getMinutes } from 'date-fns';
import { Calendar } from 'primereact/calendar';


const minutesOneDay = 24 * 60;
export const renderMinutesAsString = (minutes) => { return format(addMinutes(startOfDay(new Date()), minutes), 'HH:mm') }
export const computeTime = (minutes) => { return addMinutes(startOfDay(new Date()), minutes) }
export const computeMinutes = (time) => { return (getHours(time) * 60 + getMinutes(time)) }

export const TimePickerChecked = ({ id, labelStart, labelEnd, formik, setShowDialog, steps }) => {
	// 1 Tag = 288  2 Tage = 576
	const [start, setStart] = useState('');
	const [end, setEnd] = useState('');

	useEffect(() => {
		setStart(computeTime(formik.values[id][0]))
		setEnd(computeTime(formik.values[id][1]))
	}, [formik.values[id]])

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error">{formik.errors[id]}</small>;
	};

	const handleOnChange = (selectedInterval) => {
		formik.setFieldValue(id, selectedInterval);
		setStart(computeTime(selectedInterval[0]));
		setEnd(computeTime(selectedInterval[1]));
		setShowDialog(true);
	}

	const handleOnChangeCalendarFields = (start, newEnd) => {
		setStart(start);
		setEnd(newEnd);

		const intervalStart = computeMinutes(start);
		let intervalEnd = computeMinutes(newEnd)
		if (formik.values[id][1] > minutesOneDay) {
			intervalEnd += minutesOneDay
		}
		formik.setFieldValue(id, [intervalStart, intervalEnd]);
		setShowDialog(true);

	}

	return (
		<div className="p-field p-col p-d-flex p-flex-column">
			<div className='p-d-flex p-jc-between'>
				<label>{labelStart}</label>
				<label>{labelEnd}</label>
			</div>
			<div className='p-d-flex p-jc-between'>
				<Calendar style={{ width: '80px' }} id="timeStart" value={start} onChange={(e) => handleOnChangeCalendarFields(e.value, end)} timeOnly hourFormat="24" />
				<Calendar style={{ width: '80px' }} id="timeEnd" value={end} onChange={(e) => handleOnChangeCalendarFields(start, e.value)} timeOnly hourFormat="24" />
			</div>

			<Slider key={'input-' + id}
				min={0} max={48 * 60}
				step={steps} range animate value={formik.values[id]} onChange={(e) => { handleOnChange(e.value) }}
			/>
			<div className='p-d-flex p-grid p-jc-between p-m-1'>
				<div className='p-col p-p-2' style={{ border: 'solid 0px red', borderRightWidth: '3px' }}><div className='p-d-flex p-jc-center'>{'Tag1'}</div> </div>
				<div className='p-col p-p-2'><div className='p-d-flex p-jc-center'>{'Tag2'}</div></div>
			</div>
			{getFormErrorMessage(id)}
		</div>
	)

}
