import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useMediaQuery } from '../Utils/useMediaQuery'
import { Button } from 'primereact/button';
import { useEmployee } from '../Persistence/EmployeeContext';
import { useEffect, useState } from 'react';
import { useSelectedEmployeeContext, useSelectedEmployeeUpdate } from '../Persistence/SelectedEmployeeContext';
import logger from '../Utils/logger';
import './EmployeeList.scss'
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { parseISO } from 'date-fns';


export const EmployeeList =  ({handleOkButton,showLargeTable,setShowLargeTable}) =>{
    const [screenSize] = useMediaQuery();
    const employeeList = useEmployee();
    const selectedEmployee = useSelectedEmployeeContext();
    const handleChangeSelectedEmployee = useSelectedEmployeeUpdate();

    const renderOkButton = () =>{
        if(handleOkButton){
            return (<Button label="OK" icon="pi pi-check" onClick={handleOkButton} />)
        }else if(setShowLargeTable){
            return (<Button icon={showLargeTable?"pi pi-user-edit":"pi pi-users"} onClick={() => setShowLargeTable(!showLargeTable)} />)
        }
    }
    useEffect(()=>{
        logger.debug(`handleChangeSelectedEmployee employee:${JSON.stringify(selectedEmployee)} `);
        if(employeeList && selectedEmployee !== null){
            const _employee = employeeList.find((employee)=>employee.id ===selectedEmployee.id);
            if(_employee != null){
                handleChangeSelectedEmployee(_employee)
            }
        }
    },[employeeList,selectedEmployee])

    return(
        <div >{!showLargeTable?
            <DataTable id='employeelist_datatable' sortField="lastname" sortOrder={1} headerStyle={handleOkButton?{}:{display:'none'}}  header={renderOkButton()} style={{overflow:'auto' }} scrollable scrollHeight={(screenSize[1]-300) +'px'}   value={employeeList} selection={selectedEmployee} onSelectionChange={(e) => {handleChangeSelectedEmployee(e.value)}} dataKey="id" className="p-datatable-sm">
                <Column field="lastname" sortable headerStyle={{display:'none'}} bodyStyle={{display:'none',width: '0px'}} filter filterPlaceholder="Suche nach Name" ></Column>
                <Column selectionMode="single"  headerStyle={{display:'none'}} bodyStyle={{width: '2em'}}></Column>
                <Column body={(rowData) => `${rowData.lastname}, ${rowData.firstname}`} headerStyle={{display:'none'}} ></Column>
            </DataTable>:
            <DataTable id='employeelist_datatable' sortField="lastname" sortOrder={1} headerStyle={handleOkButton?{}:{display:'none'}}  header={renderOkButton()} style={{overflow:'auto' }} scrollable scrollHeight={(screenSize[1]-300) +'px'}   value={employeeList} className="p-datatable-sm">
                <Column body={(rowData) => <i className={rowData.active?"pi pi-check":"pi pi-times"}/>}  headerStyle={{width:'50px'}}></Column>
                <Column field="lastname" header='Nachname' sortable filter filterPlaceholder="Suche nach Name"></Column>
                <Column field="firstname" header='Vorname' sortable ></Column>
                <Column field="site.name" header={'Standort'} sortable></Column>
                <Column field="phone" header={'Tel'} filter filterPlaceholder="Suche nach Telefon"></Column>
                <Column field="mobile" header={'Mobile'} filter filterPlaceholder="Suche nach Mobile"></Column>
                <Column field="G26Date" headerStyle={{width:'140px'}} body={(rowData) => (<div className={!rowData.G26Date?'':(isAfter(new Date(),parseISO(rowData.G26Date)) ?'date-overdue':'date-valid')}>{rowData.G26Date}</div>)}  header={'G26'} sortable></Column>
                <Column field="drivingLicenseDate" headerStyle={{width:'140px'}} body={(rowData) => (<div className={!rowData.drivingLicenseDate?'':(isAfter(new Date(),parseISO(rowData.drivingLicenseDate)) ?'date-overdue':'date-valid')}>{rowData.drivingLicenseDate}</div>)} header={'Führerschein'} sortable></Column>
            </DataTable>           
        }</div>

        
    );

}