import { useState, useEffect, useRef } from 'react';
import { CalendarEmployee } from '../Calendar/CalendarEmployee';
import { CalendarEVD } from '../Calendar/CalendarEVD';
import { EmployeeStatus } from '../Employee/EmployeeStatus';
import { useMediaQuery } from '../Utils/useMediaQuery'
import { Button } from 'primereact/button';
import { Sidemenu } from './Sidemenu';
import { EmployeesPage } from '../Employee/EmployeesPage';
import { EmployeePage } from '../Employee/EmployeePage';
import { EmployeeForm } from '../Employee/EmployeeForm';
import { OperationReport } from '../Operation/OperationReport';
import { OperatorView } from '../Operation/OperatorView';
import { OperatorViewCorona } from '../Operation/OperatorViewCorona';
import { ManageSimpleListPage } from '../DataMaintenance/ManageSimpleListPage';
import { TagPage } from '../DataMaintenance/TagPage';
import { UsersPage } from '../pages/UsersPage'
import { format } from 'date-fns'
import { getData } from '../feathers';
import { TerminalPage } from '../DataMaintenance/TerminalPage';
import { TerminalStatus } from './TerminalStatus';
import { TerminalProvider } from '../Persistence/TerminalContext';
import { useCurrentUser, useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { EmployeeProvider } from '../Persistence/EmployeeContext';
import { UserProvider } from '../Persistence/UserContext';
import { PermissionProvider } from '../Persistence/PermissionContext';
import { updateLastAction, useLogin } from '../Persistence/LoginContext'
import logger, { LoggerContext, LoggerLogin } from '../Utils/logger';
import { reactLocalStorage } from 'reactjs-localstorage';
import { TieredMenu } from 'primereact/tieredmenu';
import { ReportProvider } from '../Persistence/ReportContext';
import { AvailabilityProvider } from '../Persistence/AvailabilityContext';
import { parseISO } from 'date-fns';
import { EmployeeTimeEntryTemplate } from '../Employee/EmployeeTimeEntryTemplate';

const createItems = (label, loglevel, loglevelFunction, _logger) => {
	return [
		{
			label: label + '->' + loglevel, items: [
				{ label: 'error', icon: 'pi pi-caret-right', command: () => { _logger.level = 'error'; loglevelFunction('error') } },
				{ label: 'info', icon: 'pi pi-caret-right', command: () => { _logger.level = 'info'; loglevelFunction('info') } },
				{ label: 'debug', icon: 'pi pi-caret-right', command: () => { _logger.level = 'debug'; loglevelFunction('debug') } },
			]
		},
	]
}

export const NavigationPage = () => {

	const [screenSize] = useMediaQuery();
	const [visibleMenu, setVisibleMenu] = useState(false);
	const [component, setComponent] = useState();
	const [loglevelLogin, setLoglevelLogin] = useState(reactLocalStorage.get('loglevel', 'error'));
	const [loglevelDefault, setLoglevelDefault] = useState(reactLocalStorage.get('loglevel', 'error'));
	const [loglevelContext, setLoglevelContext] = useState(reactLocalStorage.get('loglevel', 'error'));
	const [items, setItems] = useState();
	const menu = useRef(null);
	const currentUser = useCurrentUser();
	const login = useLogin();
	const isAdmin = useCurrentUserIsAdmin();


	useEffect(() => {

		if (!currentUser) {
			handleSidemenuCommand('')
		} else if (currentUser.employee) {
			handleSidemenuCommand(screenSize[0] > 1300 ? 'EmployeePage' : 'EmployeeStatus')
		} else if (currentUser.roles.includes("operator-view")) {
			handleSidemenuCommand('OperatorViewCorona')
			//handleSidemenuCommand('')
		} else if (currentUser.roles.includes("hr")) {
			handleSidemenuCommand('EmployeesPage')
		} else { handleSidemenuCommand('') }
	}, [currentUser])

	const handleSidemenuCommand = async (componentname) => {
		switch (componentname) {
			case 'EmployeePage':
				setVisibleMenu(false)
				setComponent(() => <EmployeePage employee={currentUser.employee} />);
				break;
			case 'EmployeeTimeEntryTemplate':
				setVisibleMenu(false)
				setComponent(() => <EmployeeTimeEntryTemplate employee={currentUser.employee} />);
				break;
			case 'OperatorView':
				setVisibleMenu(false)
				setComponent(() => <OperatorView />);
				break;
			case 'OperatorViewCorona':
				setVisibleMenu(false)
				setComponent(() => <AvailabilityProvider><OperatorViewCorona /></AvailabilityProvider>);
				break;
			case 'EmployeeCalendar':
				setVisibleMenu(false)
				setComponent(() => <CalendarEmployee employee={currentUser.employee} />);
				break;
			case 'CalendarEVD':
				setVisibleMenu(false)
				setComponent(() => <CalendarEVD />);
				break;
			case 'EmployeeStatus':
				setVisibleMenu(false)
				setComponent(() => <div className="p-d-flex p-jc-center" style={{ margin: 'auto', width: '100%' }}><div><EmployeeStatus employee={currentUser.employee} /></div></div>);
				break;
			case 'EmployeeForm':
				setVisibleMenu(false)
				const employee = await getData('employee', currentUser.employee.id)
				setComponent(() => <div className="p-d-flex p-jc-center" style={{ margin: 'auto', width: '100%' }}><div><EmployeeForm employee={employee} editMode={true} /></div></div>);
				break;
			case 'EmployeesPage':
				setVisibleMenu(false)
				setComponent(() => <EmployeesPage personButtons={{ center: 'myCustomButton' }} />);
				break;
			case 'OperationReport':
				setVisibleMenu(false)
				setComponent(() => <ReportProvider><OperationReport /></ReportProvider>);
				break;
			case 'ManageBusinessUnits':
				setVisibleMenu(false)
				setComponent(() => <ManageSimpleListPage listname='Unternehmensbereiche' servicename='business-unit' />);
				break;
			case 'ManageSites':
				setVisibleMenu(false)
				setComponent(() => <ManageSimpleListPage listname='Standorte' servicename='site' />);
				break;
			case 'ManageTerminals':
				setVisibleMenu(false)
				setComponent(() => <TerminalPage />);
				break;
			case 'ManageOrganizationFunctions':
				setVisibleMenu(false)
				setComponent(() => <ManageSimpleListPage listname='Organisations Funktionen' servicename='organization-function' />);
				break;
			case 'ManageQualificationFunctions':
				setVisibleMenu(false)
				setComponent(() => <ManageSimpleListPage listname='Qualifikations Funktionen' servicename='qualification-function' />);
				break;
			case 'openTags':
				setVisibleMenu(false)
				setComponent(() => <EmployeeProvider><TagPage listname='Qualifikations Funktionen' servicename='qualification-function' /></EmployeeProvider>);
				break;
			case 'ManageUsers':
				setVisibleMenu(false)
				setComponent(() => <PermissionProvider><UserProvider><UsersPage /></UserProvider></PermissionProvider>);
				break;
			default:
				setVisibleMenu(false)
				setComponent(() => <div><h1>Nichts selectiert</h1></div>);
				break;
		}
	}

	useEffect(() => {
		const items1 = createItems('DEFAULT', loglevelDefault, setLoglevelDefault, logger);
		const items2 = createItems('LOGIN', loglevelLogin, setLoglevelLogin, LoggerLogin);
		const items3 = createItems('CONTEXT', loglevelContext, setLoglevelContext, LoggerContext);
		setItems(items1.concat(items2, items3));
	}, [loglevelDefault, loglevelLogin, loglevelContext])

	useEffect(() => {
		var handlekeyDown = window.addEventListener('keydown', (event) => { updateLastAction(); });
		var handleClick = window.addEventListener('click', (event) => { updateLastAction(); });
		return () => { logger.debug('clear Inteval timeIntervalExpire & eventListener'); window.removeEventListener('click', handleClick); window.removeEventListener('keydown', handlekeyDown); }
	}, []);

	const renderLogger = () => {
		return <Button label="Logger" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
	}

	const renderExpireTime = () => {
		if (login) {
			const expireTime = parseISO(reactLocalStorage.get('expireTime', '2000-01-01T00:00:00Z'));
			const expireTimeRefreshToken = parseISO(reactLocalStorage.get('expireTimeRefreshToken', '2000-01-01T00:00:00Z'));
			return <b><span style={{ color: 'white' }}>{format(expireTime, 'yyyy-MM-dd HH:mm') + " - " + format(expireTimeRefreshToken, 'yyyy-MM-dd HH:mm')}</span></b>
		}
		return <b><span style={{ color: 'white' }}>----</span></b>
	}

	return (
		<TerminalProvider>
			<TieredMenu model={items} popup ref={menu} id="popup_menu" />
			<div className="p-card p-d-flex p-flex-column" style={{ height: (screenSize[1]) + 'px' }}>
				<div className="p-panel-content" id='navigation-headline'>
					<div className="p-panel-content p-d-flex p-m-1 " >
						<div className='hamburger-menu'>
							<Button icon="pi pi-bars" onClick={(e) => setVisibleMenu(true)} />
						</div>
						<div className=" p-d-flex p-as-center">
							<b><span style={{ color: 'white' }}>{(currentUser.displayname)}</span></b>
						</div>
						{isAdmin ?
							<div className="p-as-center">
								<b><span style={{ color: 'white' }}><TerminalStatus /></span></b>
							</div> : ''
						}
						<div className='p-mr-4 p-as-center'>
							{isAdmin ? renderLogger() : ''}
						</div>
						<div className='p-mr-4 p-as-center'>
							{isAdmin ? renderExpireTime() : ''}
						</div>
					</div>
				</div>
				<div className='p-card navigation-page-content' style={{ height: (screenSize[1] - 52) + 'px' }}>
					<div className="p-d-flex" style={{ height: '100%' }}>
						{component}
					</div>
				</div>
			</div>
			<Sidemenu handleSidemenuCommand={handleSidemenuCommand} setVisibleMenu={setVisibleMenu} visibleMenu={visibleMenu} />
		</TerminalProvider>
	);
}