import React, { useEffect, useRef, useState } from 'react';
import { EmployeeForm } from './EmployeeForm';
import { EmployeeList } from './EmployeeList';
import { EmployeeStatus } from './EmployeeStatus'
import { OverlayPanel } from 'primereact/overlaypanel';
import { CalendarEmployee } from '../Calendar/CalendarEmployee'
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { EmployeeProvider } from '../Persistence/EmployeeContext';
import { createPassword } from '../feathers';
import { emptyEmployee, useSelectedEmployeeContext, useSelectedEmployeeUpdate } from '../Persistence/SelectedEmployeeContext';
import logger from '../Utils/logger';
import { EmployeeTimeEntryTemplate } from './EmployeeTimeEntryTemplate';

export const EmployeesPage = () => {

	const [showLargeTable, setShowLargeTable] = useState(true);
	const opEmployee = useRef(null);
	const isMounted = useRef(false);
	const selectedEmployee = useSelectedEmployeeContext();
	const setSelectedEmployee = useSelectedEmployeeUpdate();

	useEffect(() => {
		if (isMounted.current) {
			opEmployee.current.hide();
		}
	}, [])

	useEffect(() => {
		isMounted.current = true;
	}, [])

	var hideOverlayPanel = () => { opEmployee.current.hide() }

	const handleChangeSelectedEmployee = async (employee) => {
		if (employee != null) {
			if (!employee.id) {
				employee.user.password = await createPassword();
			}
			logger.debug(`handleChangeSelectedEmployee employee: ${JSON.stringify(employee)}`);

			setSelectedEmployee(employee);
		}
	}

	const renderEmployeeForm = () => {
		return <TabPanel id='nav-page-tabview' header='Stammdaten'>
			<div className="p-d-flex p-ai-center">
				<div className="p-mr-4"><h2>{(selectedEmployee && selectedEmployee.id ? 'MitarbeiterIn: ' + selectedEmployee.lastname + ', ' + selectedEmployee.firstname : 'keine Person selektiert')}</h2></div>
				<div className="p-mr-2"><Button className={(selectedEmployee && selectedEmployee.id ? '' : 'p-d-md-none')} label='bearbeiten' onClick={(e) => { opEmployee.current.toggle(e) }} /></div>
				<div className="p-mr-2"><Button className='' label='neuer Mitarbeiter' onClick={(e) => { handleChangeSelectedEmployee({ ...emptyEmployee }); opEmployee.current.toggle(e) }} /></div>
			</div>
			<EmployeeForm
				employee={selectedEmployee ? selectedEmployee : { ...emptyEmployee }}
				editMode={false} />
			<OverlayPanel dismissable={false} ref={opEmployee} showCloseIcon id="employee_overlay_panel" style={{ height: '700px', width: '800px' }}>
				<EmployeeForm employee={{ ...selectedEmployee }} editMode={true} hideOverlayPanel={hideOverlayPanel} />
			</OverlayPanel>
		</TabPanel>
	}

	const renderEmployeeTimeTemplates = () => {
		return <TabPanel id='nav-page-tabview' header='Vorlagen Zeiteinträge'>
			<EmployeeTimeEntryTemplate employee={selectedEmployee} />
		</TabPanel>
	}

	const renderEmployeeStatus = () => {
		return <TabPanel id='nav-page-tabview' header='Status'>
			<EmployeeStatus employee={selectedEmployee} />
		</TabPanel>
	}

	const renderEmployeeCalendar = () => {
		return <TabPanel id='nav-page-tabview' header='Kalender'>
			<CalendarEmployee employee={selectedEmployee} />
		</TabPanel>
	}


	return (
		<React.StrictMode>
			<div className="p-grid p-m-4" >
				<div className={showLargeTable ? 'p-col-12' : "p-col-3"} >
					<EmployeeProvider>
						<EmployeeList showLargeTable={showLargeTable} setShowLargeTable={setShowLargeTable} />
					</EmployeeProvider>
				</div>

				<div className={showLargeTable ? 'p-d-none' : "p-col-9"} >
					<TabView id='nav-page-tabview'>
						{renderEmployeeForm()}
						{renderEmployeeCalendar()}
						{renderEmployeeStatus()}
						{renderEmployeeTimeTemplates()}
					</TabView>
				</div>

			</div>
		</React.StrictMode>
	);


}